import request from '@/utils/requestV2'

// 根据用户ID获取水印图片
export function getUserWaterImage (data) {
  return request({
    url: '/ooh-dw/v1/datav/image/getUserWaterImage',
    method: 'get',
    params: data
  })
}
